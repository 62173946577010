* {
  margin: 0 auto;
  box-sizing: border-box;
  
}

a {
  text-decoration: none;
  color: #FFF;
}

.App {
  text-align: left;
  background-image: url('./assets/portoflioBG.png');
  background-size: cover;
}


/* HEADER */

.header {
  display: flex;
  flex-direction: column;
  color: #373737;
  font-family: 'Alata', sans-serif;
  height: 8vh;
  background-color: #FF5F55;
  position: fixed; /* Makes the header fixed at the top */
  top: 0; /* Positions it at the very top */
  width: 100%; /* Ensures it spans the full width */
  z-index: 1000; /* Ensures it stays above other elements */
  box-shadow: 0px 0px 12px #FF5F55; /* Bottom-only shadow */
}

.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  font-size: 1rem;
  margin-top: 3vh;
  gap: 6rem;
  font-weight: 600;
}

.nav li {
  transition: transform 0.3s ease, font-weight 0.3s ease; /* Smooth zoom and font-weight change */
}

.nav li:hover {
  transform: scale(1.02); /* Slight zoom-out effect */
  font-weight: 700; /* Slightly heavier font-weight */
  color: #ffd94e;
}

.nav li a {
  transition: color 0.3s ease; /* Smooth color change */
}

.nav li a:hover {
  color: #ffd94e;
}


/* HOMEPAGE */

.home-body-container {
  height: 100vh;
  display: grid;
  grid-template-columns: 50% 50%;
  position: relative;
}

.homepage-title {
  color: #FFFFFF;
  justify-items: center;
  justify-self: center;
  margin-top: 28%;
  margin-left: 22%;
}

.homepage-title h1 {
  font-size: 4rem;
  font-family: 'Shippori Antique B1', sans-serif;
}

.homepage-title h2 {
  font-weight: 400;
  margin-top: 5%;
  font-size: 1.2rem;
  line-height: 1.7rem;
}

.homepage-title p {
  font-weight: 700;
  margin-top: 2%;
  transition: transform 0.3s ease, font-weight 0.3s ease;
}

.homepage-title p:hover {
  transform: scale(1.01); /* Slight zoom-out effect */
  font-weight: 700; /* Slightly heavier font-weight */
}


.homepage-img {
  position: relative;
  flex: 1; /* Takes available space next to title */
  display: flex;
  justify-content: center; /* Center horizontally within container */
  align-items: center; /* Center vertically within container */
  margin-right:65%;
  margin-top: 15%;
}

.homepage-img img {
  position: absolute;
  width: 85vw;
  height: auto;
  transition: opacity 1s ease-in-out;
  opacity: 0; /* Hidden by default */
}

.homepage-img img.active {
  opacity: 1;
}



/* ABOUT ME */

.home-body-container-two {
  height: 100vh;
  display: grid;
  grid-template-columns: 50% 50%;
  position: relative;
}

.aboutme-skills {
  margin-left: -5%;
  margin-top: 10%;
  opacity: 0; /* Start hidden */
  transform: translateY(-1cm); /* Start 1 cm above */
  animation: fadeInSlideDown 1s forwards ease-in-out; /* Animation on load */
}

.aboutme-skills h2 {
  margin-bottom: 2%;
}

.aboutme-skills h3 {
line-height: 1.8rem;
  margin-top: 2%;
}

.aboutme-skills h3 span {
  font-weight: 400;
}

.aboutme-content {
  margin-top: -7%;
  margin-right: 5%;
  padding: 3% 10% 0 4%;
  margin-bottom: -5%;
  position: relative;
  min-height: 300px;
}

.aboutme-content p {
  font-size: 1.2rem;
  color:rgb(255, 50, 50);
  line-height: 1.5rem;
}

.aboutme-content h4 {
  color:rgb(255, 50, 50);
  padding-bottom: 3%;
  font-size: 1.4rem;
}

.aboutme-content button {
  margin-top: 5%;
  margin-left: 5%;
  background-color: rgb(255, 50, 50);
  border: none;
  margin: 8%  0 0 0;
  color: white;
  font-weight: bolder;
  font-size: 0.9rem;
  border-radius: 2rem;
  padding: 2%;
  width: 10vw;
}

.aboutme-content button:hover {
  color: darkblue;
  background-color: #FFDE67;
}

.study-bg-list {
  margin-top: 10%;
  margin-left: -8%;
  opacity: 0; /* Start hidden */
  transform: translateY(-1cm); /* Start 1 cm above */
  animation: fadeInSlideDown 1s forwards ease-in-out; /* Animation on load */
}

.aboutme-intro{
  margin-top: 10%;
  margin-left: -5%;
  opacity: 0; /* Start hidden */
  transform: translateY(-1cm); /* Start 1 cm above */
  animation: fadeInSlideDown 1s forwards ease-in-out; /* Animation on load */
}

@keyframes fadeInSlideDown {
  from {
    opacity: 0;
    transform: translateY(-0.7cm); /* Starts above */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Ends at original position */
  }
}

.study-bg-list li {
  line-height: 2rem;
  font-size: 1rem;
  margin-top: 2%;
}

.icon-wrapper {
  display: inline-flex; /* Ensures content is tightly wrapped */
  justify-content: center; /* Center the icon horizontally */
  align-items: center; /* Center the icon vertically */
  width: 3rem; /* Slightly larger than the icon size to create a hover area */
  height: 5rem; /* Same as above */
  cursor: pointer; /* Show pointer to indicate clickability */
  position: relative; /* Keep the icon stable within the wrapper */
  overflow: hidden; /* Prevent any overflow caused by transitions */
}

.down-arrow,
.up-arrow {
  font-size: 3rem;
  color: #FFF;
  transition: all 0.3s ease; /* Smooth hover effect */
}

.icon-wrapper:hover .down-arrow,
.icon-wrapper:hover .up-arrow {
  color: #ffd94e;
  transform: scale(1.1); /* Slight zoom effect without moving the icon */
}

.omakuva {
  width: 36vw;
  height: 36vw;
  border-radius: 50%;
  object-fit: cover;
  border: 10px solid #ffffff;
  filter: saturate(1) brightness(0.95) contrast(1.5);
  margin-left: -15%;
  margin-top: 30%;
}



/* WORK PAGE */

.work-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Two items per row */
  gap: 3rem; /* Add spacing between grid items */
  padding: 7rem 5rem; /* Add padding around the grid */
}

.work-item {
  background: #fff; /* Background color for individual items */
  border-radius: 2.5rem; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  overflow: hidden; /* Ensures the content fits nicely */
  padding: 1rem 2rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth animation for hover effect */
}

.work-item:hover {
  transform: scale(1.02); /* Slight zoom-out effect */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
}

.work-item img {
  width: 140%;
  height: auto;
  margin-bottom: 3%;
  margin-top: 8%;
  margin-left: -17%;
}

.work-details {
  padding: 1.5rem 1rem;
}

.work-details h2 {
  margin-bottom: 1%;
  color:rgb(255, 50, 50);
  font-size: 1.7rem;
}

.work-details h4 {
  font-weight: 400;
  margin-bottom: 2%;
  color: rgb(50, 50, 50);
}

.work-details p {
  margin-top: 3%;
  font-weight: bold;
  color: rgb(50, 50, 50);
}

.work-details h3 {
  margin-top: 3%;
  font-weight: 500;
  color: rgb(50, 50, 50);
  font-size: 1rem;
  margin-top: 10%;
  border: 0.5px solid rgb(255, 50, 50);
  padding: 5%;
  border-radius: 1rem;
  background: #ffd94e;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.work-buttons {
  display: flex;
  justify-content: center;
  gap: 5%;
  margin: 10% 20%;
}

.work-buttons button {
  padding: 0.5rem 1rem;
  background-color: #FF5F55;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  font-weight: bold;
  transition: color 0.5s ease, box-shadow 0.3s ease;
  font-size: 0.9rem;
  color: #FFF;
}

.work-buttons button:hover {
  color: darkblue;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05)
}

.video-container {
  position: relative;
  display: inline-block;
  margin-bottom: 2%;
  margin-top: 2%;
}

.project-image {
  width: 100%;
  height: auto;
}

.video-container.small-image img {
  width: 30%; /* Adjust to desired size */
  height: auto;
  margin-left: 20%;
  margin-top: 5%;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  color: grey;
  cursor: pointer;
  transition: all 0.3s ease;
}

.play-icon:hover {
  color: #FF5F55;
  font-size: 4.5rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  border: 5px solid #FF5F55;
}

.modal-content {
  background: #fff;
  padding: 3rem 10rem;
  border-radius: 1.5rem;
  position: relative;
}

.close-modal {
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  background: #FF5F55;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  color: #fff;
  transition: all 0.3s ease;
}

.close-modal:hover {
  color: black;
}

.modal-video {
  width: 80%;
  margin-left: 10%;
  height: auto;
}


/* CONTACT */

.contact-container {
  background: #FFDE67;
  padding: 5% 0 5% 0;
  color: rgb(255, 50, 50);
  text-align: center;
}

.contact-container h1 {
  font-size: 2.5rem;
  font-family: 'Shippori Antique B1', sans-serif;
}

.contact-divider {
  border-bottom: white solid 2px;
  width: 10%;
  margin-bottom: 2%;
}

.contact-info h4 {
  font-size: 1.1rem;
  margin-top: 3%;
  font-weight: 400;
}

.contact-info a {
  font-weight: 600;
  color: rgb(255, 50, 50);
  position: relative; /* Enables positioning for ::after */
  text-decoration: none; /* Removes default underline */
  display: inline-block; /* Ensures proper placement of the underline */
}

.contact-info a::after {
  content: '';
  position: absolute;
  bottom: 0; /* Places the underline directly beneath the text */
  left: 0; /* Starts at the left edge */
  width: 100%; /* Full width of the underline */
  height: 1px; /* Thickness of the underline */
  background-color: darkblue; /* Color of the underline */
  opacity: 0; /* Initially invisible */
  transition: opacity 0.5s ease; /* Smooth fade-in effect */
}

.contact-info a:hover::after {
  opacity: 1; /* Makes the underline visible on hover */
}

.email {
  cursor: pointer;
  position: relative;
  text-decoration: none; /* Removes default underline */
  color: inherit; /* Ensures it takes the parent text color */
  display: inline-block; /* Ensures proper placement of the underline */
  font-weight: 600;
}

.email::after {
  content: '';
  position: absolute;
  bottom: 0; /* Places the underline directly beneath the text */
  left: 0; /* Starts at the left edge */
  width: 100%; /* Full width of the underline */
  height: 1px; /* Thickness of the underline */
  background-color: darkblue; /* Color of the underline */
  opacity: 0; /* Initially invisible */
  transition: opacity 0.5s ease; /* Smooth fade-in effect */
}

.email:hover::after {
  opacity: 1; /* Makes the underline visible on hover */
}



.aboutme-content {
  margin-top: -7%;
  margin-right: 5%;
  padding: 3% 10% 0 4%;
  margin-bottom: -5%;
  position: relative; /* Parent for positioning */
  min-height: 300px;
}

.aboutme-content p {
  font-size: 1.2rem;
  color:rgb(255, 50, 50);
  line-height: 1.5rem;
}

.aboutme-content h4 {
  color:rgb(255, 50, 50);
  padding-bottom: 3%;
  font-size: 1.4rem;
}

.aboutme-content button {
  margin-top: 5%;
  margin-left: 5%;
  background-color: rgb(255, 50, 50);
  border: none;
  margin: 8%  0 0 0;
  color: white;
  font-weight: bolder;
  font-size: 0.9rem;
  border-radius: 2rem;
  padding: 2%;
  width: 10vw;
}

.aboutme-content button:hover {
  color: darkblue;
  background-color: #FFDE67;
}


@media (max-width: 600px) {

  .App {
    background-position: 30% 60%;
    background-repeat:repeat;
  }

  .header {
    height: 6vh;
    position: fixed;
    z-index: 1000;
    top: 0; 
    left: 0;
  }
  
  .nav {
    display: flex;
    margin-left: 0;
    font-size: 1rem;
    margin-top: 2.5vh;
    gap: 1.2rem;
  }
  

  .home-body-container {
    grid-template-columns: 1fr;
    grid-template-rows: 100%;
    margin: 10%;
    margin-bottom: -30%;
  }
  
  .homepage-title {
    margin-top: 20%;
    margin-left: 5%;
  }
  
  .homepage-title h1 {
    font-size: 3rem;
  }
  
  .homepage-title h2 {
    font-weight: 400;
    margin-top: 5%;
    font-size: 1.1rem;
    line-height: 1.7rem;
  }

  .homepage-title h3 {
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 1%;
  }

  .homepage-img {
    display: none;
  }


  .home-body-container-two {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    margin: 10%;
    margin-bottom: 10%;
  }

  .omakuva {
    width: 60vw;
    height: 60vw;
    margin-left: 0%;
    margin-top: 5%;
  }


  .contact-container h1 {
    font-size: 1.5rem;
  }
  
  .contact-divider {
    width: 18%;
    margin-bottom: 4%;
    margin-top: 0.5%;
  }
  
  .contact-info h4 {
    font-size: 1rem;
    margin-top: 3%;
    margin-bottom: 3%;
  }
  
  .work-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;
    padding: 4.5rem 2rem;
  }
  
  .work-item {
    background: #fff; /* Background color for individual items */
    border-radius: 2.5rem; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    overflow: hidden; /* Ensures the content fits nicely */
    padding: 1rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth animation for hover effect */
  }

  .work-details h4 {
    margin-top: 5%;
  }
  
  .modal-content {
    background: #fff;
    padding: 4rem 3rem;
    border-radius: 1.5rem;
    position: relative;
  }
  
  .close-modal {
    font-size: 1rem;
  }
  
  .modal-video {
    width: 90%;
    margin-left: 6%;
  }

}


@media screen and (max-width: 375px) and (max-height: 667px) {
  .contact-container {
    margin-top: 70%;
  }
}